import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { clusterApiService } from "@/services/cluster/cluster-api.service/cluster-api.service";
import { workloadServiceApi } from "@/services/infra/client-apis/workloads-service-api/workloads-service-api";
import { httpService } from "@/services/infra/https.service/http.service";
import { inferenceService } from "@/services/control-plane/inference.service/inference.service";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";

import type { AxiosResponse } from "axios";
import type {
  WorkloadMetricType,
  MetricsResponse,
  Pod,
  PodMetricType,
  PodVerbosity,
  WorkloadSortFilterFields,
  BatchResponse,
  WorkloadDetailed,
  PendingSchedulingMessage,
} from "@/swagger-models/workloads-service-client";

import { API, RESEARCHER } from "@/common/api.constant";
import type {
  IWorkloadMetadata,
  IWorkloadSpec,
  IWorkload,
  IWorkloadResponse,
  IGetWorkloadPods200Response,
  EWorkloadTelemetryGroupBy,
} from "@/models/workload.model";
import { EWorkloadType } from "@/models/workload.model";

import { ForbiddenError, HttpErrorResponse, NotFoundError, ServiceUnavailableError } from "@/models/http-response.model";
import type { IFilterBy, IPaginationFilter } from "@/models/filter.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type {
  GetWorkloadHistory200Response,
  GetWorkloads200Response,
  TelemetryResponse,
  Workload,
  WorkloadPatchFields,
  WorkloadTelemetryType,
} from "@/swagger-models/workloads-service-client";
import { httpResponseService } from "@/services/infra/http-response.service/http-response.service";
import { Phase } from "@/swagger-models/workloads-service-client";
import { workloadLocalPhaseUpdater } from "@/services/cluster/workload.service/local-status-updater";
import { filterService } from "@/services/filter.service/filter.service";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_INFERENCE_AUTO_DELETE } from "@/common/version.constant";
import { fileService, FileType } from "@/services/file.service/file.service";
import { workloadIndexColumns } from "@/table-models/workload.table-model";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
export const workloadService = {
  createWorkload,
  deleteWorkload,
  deleteWorkloadV2,
  stopWorkloadV2,
  activateWorkloadV2,
  list,
  stopWorkload,
  activate,
  getWorkloadPods,
  getWorkloadHistory,
  getWorkloadById,
  getWorkloadsCount,
  getWorkloads,
  handleFailedWorkloadClusterCreation,
  getWorkloadsTelemetry,
  getWorkloadsTelemetryCsv,
  countWorkloadsByName,
  getWorkloadMetrics,
  listPods,
  patchWorkloadById,
  getWorkloadPodMetrics,
  deleteMultipleWorkloads,
  getWorkloadPendingSchedulingMessages,
};

const WORKLOADS_RESEARCHER_ENDPOINT = `${RESEARCHER.v1}/workload/proxy`;
const apiVersion = "run.ai/v2alpha1";
const WORKLOADS_SERVICE_ENDPOINT = `${API.v1}/workloads`;
const WORKLOADS_TELEMETRY_ENDPOINT = `${API.v1}/workloads/telemetry`;

interface IErrorResponseData {
  name: string;
  ok: boolean;
  error?: {
    message: string;
    details: string;
    status: number;
  };
}
interface IErrorResponse {
  data: IErrorResponseData[];
}

// api calls
async function createWorkload(
  kind: string,
  metadata: IWorkloadMetadata,
  spec: IWorkloadSpec,
  masterSpec?: IWorkloadSpec,
): Promise<IWorkloadResponse> {
  const workload: IWorkload = {
    apiVersion,
    kind,
    metadata,
    spec,
    masterSpec,
  };

  try {
    const workloadResponse = await clusterApiService.post(
      `${WORKLOADS_RESEARCHER_ENDPOINT}/namespaces/${metadata.namespace}/${kind}`,
      workload,
    );
    return workloadResponse;
  } catch (error: unknown) {
    if (error instanceof HttpErrorResponse) {
      switch (error.statusCode) {
        case 401:
          error.message = "The API server is not configured correctly. Contact your administrator.";
          break;
        case 502:
        case 504:
          error.message =
            "There are issues with your connection to the cluster. Make sure you're using your organization's VPN.";
          break;
        default:
          error.message = "There are issues with your connection to the cluster. Contact your administrator.";
      }
    }
    throw error;
  }
}

async function getWorkloads(clusterUuid: string, filters?: IPaginationFilter): Promise<GetWorkloads200Response> {
  const response = await workloadServiceApi.workloadsApi.getWorkloads(
    filters?.deleted,
    filters?.offset,
    filters?.limit,
    filters?.sortOrder,
    filters?.sortBy as WorkloadSortFilterFields | undefined,
    [...(filters?.filterBy || []), `clusterId==${clusterUuid}`],
  );
  return response.data;
}

async function list(clusterUuid: string, filterBy: IFilterBy = {}, deleted = false): Promise<Workload[]> {
  const filters: IPaginationFilter = filterService.mapColumnsFilterToFilterParams(filterBy, true);
  const response: GetWorkloads200Response = await getWorkloads(clusterUuid, { ...filters, deleted });
  const filteredBySearchTermResults = filterService.filterBySearchTerm(
    response.workloads,
    filterBy.searchTerm || "",
    filterBy.displayedColumns || [],
    workloadIndexColumns,
  );
  return workloadLocalPhaseUpdater.updateWorkloadsByLocalPhases(filteredBySearchTermResults);
}

async function getWorkloadsCount(
  clusterUuid: string,
  filterBy?: Array<string>,
  deleted = false,
): Promise<{ count: number }> {
  const response = await workloadServiceApi.workloadsApi.countWorkloads(deleted, [
    ...(filterBy || []),
    `clusterId==${clusterUuid}`,
  ]);
  return response.data;
}

const _isWorkloadProjectExists = async (workload: Workload): Promise<boolean> => {
  try {
    await orgUnitService.getProject(workload.projectId);
    return true;
  } catch (e) {
    return false;
  }
};

const _has404Error = (response: IErrorResponse): boolean => {
  if (!response || !response.data || !response.data.length) {
    return false;
  }

  return response.data.some((item: IErrorResponseData) => item.error && item.error.status === NotFoundError.statusCode);
};

const _has503Error = (response: IErrorResponse): boolean => {
  if (!response || !response.data || !response.data.length) {
    return false;
  }

  return response.data.some(
    (item: IErrorResponseData) => item.error && item.error.status === ServiceUnavailableError.statusCode,
  );
};

function _has207WithErrors(response: IErrorResponse): boolean {
  if (!response || !response.data || !response.data.length) {
    return false;
  }

  return response.data.some((item: IErrorResponseData) => item.error && item.error.status >= 400);
}

async function deleteMultipleWorkloads(workloadIds: Array<string>): Promise<BatchResponse> {
  return (await workloadServiceApi.workloadsBatchApi.batchWorkloads({ ids: workloadIds, action: "delete" })).data;
}

async function deleteWorkload(workload: Workload, project: string, clusterVersion: string): Promise<void> {
  let response;

  if (workload.type === EWorkloadType.Inference) {
    response = await inferenceService.remove(workload.name, workload.namespace);

    if (!isNewerVersion(clusterVersion, MIN_INFERENCE_AUTO_DELETE)) {
      response = await assetsServiceApi.inferenceApi.deleteInferenceById(workload.id);
    }
  } else {
    response = await researcherService.deleteJob(workload.name, project, workload.type);
  }

  if (_has503Error(response)) {
    // Attempt to bypass workload ownership protection
    throw new ServiceUnavailableError("Operation not allowed");
  }

  if (_has404Error(response) || !(await _isWorkloadProjectExists(workload))) {
    //in this case workload not deleted successfully in the cluster
    await workloadService.patchWorkloadById(workload.id, {
      phase: Phase.Deleting,
      softDelete: true,
    });
  }

  if (_has207WithErrors(response)) {
    if (response.data[0].error.status === ForbiddenError.statusCode) {
      throw new ForbiddenError();
    }
  }
}

async function deleteWorkloadV2(workloadId: string, workloadType: string): Promise<void> {
  try {
    switch (workloadType) {
      case EWorkloadType.Distributed:
        return (await workloadServiceApi.distributedApi.deleteDistributed(workloadId)).data;
      case EWorkloadType.Training:
        return (await workloadServiceApi.trainingsApi.deleteTraining(workloadId)).data;
      case EWorkloadType.Workspace:
        return (await workloadServiceApi.workspacesApi.deleteWorkspace(workloadId)).data;
      case EWorkloadType.Inference:
        return (await workloadServiceApi.inferencesApi.deleteInference(workloadId)).data;
    }
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
// IMPORTANT: Our apis do not support stopping/resuming distributed workloads
async function stopWorkloadV2(workload: Workload): Promise<void> {
  try {
    switch (workload.type) {
      case EWorkloadType.Training:
        (await workloadServiceApi.trainingsApi.suspendTraining(workload.id)).data;
        break;
      case EWorkloadType.Workspace:
        (await workloadServiceApi.workspacesApi.suspendWorkspace(workload.id)).data;
        break;
    }
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
// IMPORTANT: Our apis do not support stopping/resuming distributed workloads
async function activateWorkloadV2(workload: Workload): Promise<void> {
  try {
    switch (workload.type) {
      case EWorkloadType.Training:
        (await workloadServiceApi.trainingsApi.resumeTraining(workload.id)).data;
        break;
      case EWorkloadType.Workspace:
        (await workloadServiceApi.workspacesApi.resumeWorkspace(workload.id)).data;
        break;
    }
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function stopWorkload(workload: Workload): Promise<void> {
  if (!workload.projectName) throw new Error("Can't stop workload. Job is missing.");
  const res = await researcherService.stopWorkload(workload.name as string, workload.projectName);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }
}

async function activate(workload: Workload): Promise<void> {
  const res = await researcherService.activateWorkload(workload.name as string, workload.projectName);
  workloadLocalPhaseUpdater.updateLocalPhases(workload, Phase.Resuming);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }
}

async function getWorkloadPods(workloadId: string, clusterUuid: string): Promise<IGetWorkloadPods200Response> {
  return controlPlaneService.get(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}/pods?clusterId=${clusterUuid}`);
}

async function listPods(filterBy?: Array<string>, verbosity?: PodVerbosity, completed?: string): Promise<Array<Pod>> {
  const response = await workloadServiceApi.podsApi.listPods(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    filterBy,
    verbosity,
    completed,
  );
  return response.data.pods;
}

async function getWorkloadById(workloadId: string): Promise<WorkloadDetailed> {
  return (await workloadServiceApi.workloadsApi.getWorkload(workloadId)).data;
}

async function patchWorkloadById(workloadId: string, patchFields: WorkloadPatchFields): Promise<Workload> {
  return controlPlaneService.patch(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}`, patchFields);
}

async function getWorkloadHistory(workloadId: string, clusterUuid: string): Promise<GetWorkloadHistory200Response> {
  return controlPlaneService.get(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}/history?clusterId=${clusterUuid}`);
}

async function countWorkloadsByName(name: string, clusterId: string, projectId?: number | null): Promise<number> {
  try {
    let filterBy = `name==${name},clusterId==${clusterId}`;
    if (Number.isFinite(projectId)) {
      filterBy = `${filterBy},projectId==${projectId}`;
    }

    const res = await workloadServiceApi.workloadsApi.countWorkloads(false, [filterBy]);
    return res.data.count;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

// pending scheduling messages
async function getWorkloadPendingSchedulingMessages(workloadId: string): Promise<PendingSchedulingMessage[]> {
  try {
    const workload = await getWorkloadById(workloadId);
    return workload.pendingSchedulingMessages || [];
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

// new metrics apis
async function getWorkloadMetrics(
  workloadId: string,
  start: string,
  end: string,
  metricType: Array<WorkloadMetricType>,
  numberOfSamples?: number,
): Promise<MetricsResponse> {
  try {
    const res: AxiosResponse<MetricsResponse> = await workloadServiceApi.workloadsApi.getWorkloadMetrics(
      workloadId,
      metricType,
      start,
      end,
      numberOfSamples,
    );
    return res.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function handleFailedWorkloadClusterCreation(workloadId: string, e: unknown): Promise<void> {
  try {
    let phaseMessage = "failed to create workload in cluster";
    if (e instanceof HttpErrorResponse) {
      phaseMessage = e.message;
    }
    await patchWorkloadById(workloadId, {
      phase: Phase.Failed,
      phaseMessage,
      softDelete: true,
    });
  } catch (e) {
    console.error("Failed to patch workload phase", e);
  }
}

async function getWorkloadPodMetrics(
  workloadId: string,
  podId: string,
  metricType: Array<PodMetricType>,
  start: string,
  end: string,
  numberOfSamples?: number,
): Promise<MetricsResponse> {
  try {
    const res: AxiosResponse<MetricsResponse> = await workloadServiceApi.podsApi.getWorkloadPodMetrics(
      workloadId,
      podId,
      metricType,
      start,
      end,
      numberOfSamples,
    );
    return res.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

//telemetry
async function getWorkloadsTelemetry(
  telemetryType: WorkloadTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  departmentId?: string,
  groupBy?: Array<EWorkloadTelemetryGroupBy>,
): Promise<TelemetryResponse> {
  try {
    const response = await workloadServiceApi.workloadsApi.getWorkloadsTelemetry(
      telemetryType,
      clusterId,
      nodepoolName,
      departmentId,
      groupBy,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

//telemetry csv
async function getWorkloadsTelemetryCsv(
  telemetryType: WorkloadTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  departmentId?: string,
  groupBy?: Array<EWorkloadTelemetryGroupBy>,
): Promise<void> {
  try {
    const query: Record<string, string | number | Array<string>> = {
      telemetryType: telemetryType,
    };
    if (clusterId) {
      query["clusterId"] = clusterId;
    }
    if (nodepoolName) {
      query["nodepoolName"] = nodepoolName;
    }
    if (departmentId) {
      query["departmentId"] = departmentId;
    }
    if (groupBy && groupBy.length > 0) {
      query["groupBy"] = groupBy;
    }
    await fileService.downloadFile(WORKLOADS_TELEMETRY_ENDPOINT, FileType.csv, undefined, query, {});
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
