<template>
  <quota-management-section
    :section-invalid="sectionInvalid"
    :resources="resources"
    :quota-statuses="quotaStatuses"
    :node-pools-priorities="nodePoolsPriorities"
    @update:node-pools-priorities="$emit('update:node-pools-priorities', $event)"
    @update:resources="$emit('update:resources', $event)"
    :entity="EQuotaEntity.department"
    :cluster-id="clusterId"
    :read-only="readOnly"
    :loading="loading"
  >
    <template #over-quota-toggle>
      <q-toggle
        :disable="readOnly"
        left-label
        label="Allow department to go over quota"
        :model-value="isOverQuotaOn"
        @update:model-value="allowGpuOverQuotaChanged"
      />
      <runai-tooltip
        tooltip-position="right"
        width="320px"
        tooltip-text="Departments that have quota overage disabled are limited to
                    using the GPUs assigned to them. Departments that have it
                    enabled can use GPUs beyond their quota, if needed and
                    available."
      />
    </template>
  </quota-management-section>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { DEFAULT_DEPARTMENT_DESERVED_GPUS } from "@/models/department.model";
import { EQuotaEntity, RESOURCE_MAX_ALLOWED_INFINITE_VALUE } from "@/models/resource.model";
import { useSettingStore } from "@/stores/setting.store";
import type { QuotaStatusNodePool, Resources } from "@/swagger-models/org-unit-service-client";

export default defineComponent({
  name: "department-quota-management-section",
  components: { QuotaManagementSection, RunaiTooltip },
  emits: ["update:resources", "update:node-pools-priorities", "is-section-invalid"],
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    resources: {
      type: Array as PropType<Array<Resources>>,
      required: true,
    },
    nodePoolsPriorities: {
      type: [Array, null] as PropType<Array<string> | null>,
      required: false,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    quotaStatuses: {
      type: Array as PropType<Array<QuotaStatusNodePool>>,
      default: () => [],
    },
  },
  data() {
    return {
      isOverQuotaOn: true as boolean,
      settingStore: useSettingStore(),
    };
  },
  created() {
    const isOverQuotaOn = this.resources.some(
      (resources) => resources.gpu.limit === RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
    );
    this.allowGpuOverQuotaChanged(isOverQuotaOn);
  },
  computed: {
    sectionInvalid(): boolean {
      return this.resources.some((resources) => resources.gpu.deserved === DEFAULT_DEPARTMENT_DESERVED_GPUS);
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
  },
  methods: {
    allowGpuOverQuotaChanged(isOverQuotaOn: boolean): void {
      this.isOverQuotaOn = isOverQuotaOn;
      //todo: should be remove this loop when moving department to use over quota priority node pool based - https://runai.atlassian.net/browse/RUN-21908
      const updatedResources = this.resources.map((resource) => {
        const updatedResource = { ...resource };
        updatedResource.gpu = {
          ...resource.gpu,
          limit: isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : resource.gpu.deserved,
        };

        if (resource.cpu) {
          updatedResource.cpu = {
            ...resource.cpu,
            limit: isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : resource.cpu.deserved,
          };
        }

        if (resource.memory) {
          updatedResource.memory = {
            ...resource.memory,
            limit: isOverQuotaOn ? RESOURCE_MAX_ALLOWED_INFINITE_VALUE : resource.memory.deserved,
          };
        }

        return updatedResource;
      });

      this.$emit("update:resources", updatedResources);
    },
  },
  watch: {
    sectionInvalid: {
      handler(isSectionInvalid: boolean): void {
        this.$emit("is-section-invalid", isSectionInvalid);
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
